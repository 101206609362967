import { useState } from "react";
import { MdOutlineClose, MdOutlineDataSaverOn } from "react-icons/md";
import medicament_img from "../../../assets/images/senepharmax-medicament-image.png";

function MedicamentsItem({ medicament }) {
	const [isClosed, setIsClosed] = useState(true);

	return (
		<div className="medoc-card mb-4">
			<div
				className="medoc-card__image"
				style={{ backgroundImage: `url(${medicament_img})` }}
			></div>
			<div className="medoc-card__dci">{medicament.DCI}</div>
			<div className="medoc-card__title">{medicament.medicament_nom}</div>

			<div className="medoc-card__infos clearfix">
				<div className="one-third">
					<div className="value">{medicament.forme}</div>
					<div className="attribute">FORME</div>
				</div>

				<div className="one-third">
					<div className="value">{medicament.tableau}</div>
					<div className="attribute">TABLEAU</div>
				</div>

				<div className="one-third no-border">
					<div className="value">{medicament.medicament_prix}</div>
					<div className="attribute">PRIX(FCFA)</div>
				</div>
			</div>
			<div
				className={`inside ${isClosed ? "closed" : "openned"}`}
				onClick={() => setIsClosed(!isClosed)}
			>
				<div className="open_icon">
					<MdOutlineDataSaverOn />
				</div>
				<div className="close_icon">
					<MdOutlineClose />
				</div>
				<div className="contents text-start">
					<div className="medoc-card__title">
						{medicament.medicament_nom}
					</div>
					<div className="attribute text-muted">DCI</div>
					<div className="value">{medicament.DCI}</div>
					<div className="attribute text-muted">
						CLASSE THÉRAPEUTIQUE
					</div>
					<div className="value">
						{medicament.classe_therapeutique}
					</div>
					<div className="attribute text-muted">DOSAGE</div>
					<div className="value">{medicament.dosage}</div>
					<div className="attribute text-muted">POSOLOGIE</div>
					<div className="value">{medicament.posologie}</div>
				</div>
			</div>
		</div>
	);
}

export default MedicamentsItem;
