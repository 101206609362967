import ConseilsItem from "./ConseilsItem";
import {
    Accordion,
}  from 'react-bootstrap';

function ConseilsList({ conseils }) {
    
    return (
        <Accordion defaultActiveKey="0">
            {conseils.map((conseil, index) => (
                    <ConseilsItem conseil={conseil} key={index} />
            ))}
        </Accordion>
    );
}
export default ConseilsList;
