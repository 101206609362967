// import axios from "axios";
// import { useEffect, useState } from "react";
// import { menu_infos } from "../../../data/menu_infos";

function ClassesTherapeutiquesView() {
	// const [loading, setLoading] = useState(true);
	// const [allData, setAllData] = useState([]);
	// const [filteredData, setFilteredData] = useState(allData);
	// // const [displayFilters, setDisplayFilters] = useState(true);
	// const infos = menu_infos.filter(
	// 	(menu) => menu.link === "/classes-therapeutiques"
	// )[0];

	// useEffect(() => {
	// 	axios
	// 		.get(`/api/view-therapie`)
	// 		.then((res) => {
	// 			const therapies = res.data.therapie;
	// 			console.log("therapies :", therapies);
	// 			setAllData(therapies);
	// 			setFilteredData(therapies);
	// 			setLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			console.log("ERROR getting therapies: " + err);
	// 		});
	// }, []);
	/*
    if (loading) {
        return <div className="text-danger">Loading...</div>;
    } else {
        console.log("Pharmacies : ", pharmacies);
    }
*/
	return (
		<div>
			{/* <h1 className="text-center text-dark pt-3">{infos.title}</h1>
			<p className="text-center text-dark">{infos.description}</p> */}
			<h1 className="text-center text-dark pt-3"> Bientôt disponible ! </h1>
			<p className="text-center text-dark"> Rechercher la disponibilité d'un médicament dans les pharmacies partenaires. </p>
		</div>
	);
}
export default ClassesTherapeutiquesView;
