import { Card } from "react-bootstrap";
import { FaPhone, FaSearchLocation } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import defaultImage from "../../../assets/images/senepharmax-pharmacie-item-default.png";

function PharmaciesItem({ pharmacie }) {
	return (
		<Card
			style={{
				fontSize: "14px",
			}}
			className="sn_card mb-4 w-100"
		>
			<Card.Img variant="top" src={defaultImage} />
			{pharmacie.status ? (
				<Card.Header
					//className="text-alert"
					style={{
						fontSize: "14px",
						color: "#f48c06",
						fontWeight: "bold",
					}}
				>
					De garde
				</Card.Header>
			) : (
				<Card.Header
					//className="text-danger"
					style={{ fontSize: "14px", color: "#000000" }}
				>
					Pas de garde
				</Card.Header>
			)}
			<Card.Body>
				<Card.Title
					className="text-uppercase"
					style={{ fontSize: "14px", minHeight: "50px" }}
				>
					{pharmacie.pharmacie_nom}
				</Card.Title>
				<Card.Subtitle
					className="mb-2 text-muted"
					style={{ fontSize: "14px", minHeight: "30px" }}
				>
					<GoLocation className="text-success" />{" "}
					{pharmacie.pharmacie_adresse}
				</Card.Subtitle>
				<br />
				<Card.Subtitle style={{ fontSize: "14px" }}>
					<Card.Link href={"tel:" + pharmacie.pharmacie_numero}>
						<FaPhone className="text-success" />{" "}
						{pharmacie.pharmacie_numero}
					</Card.Link>
				</Card.Subtitle>
			</Card.Body>
			<Card.Footer className="text-muted" style={{ fontSize: "14px" }}>
				<Card.Link target="_blank" href={pharmacie.map_link}>
					<FaSearchLocation /> Voir sur la carte
				</Card.Link>
			</Card.Footer>
		</Card>
	);
}

export default PharmaciesItem;
