import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function Footer() {
	const location = useLocation().pathname;

	return (
		<div id="footer_front" className="mt-5 text-center">
			<Navbar bg="white" expand="lg" variant="light">
				<Container>
					<Nav className="m-auto">
						<Nav.Link
							href="https://www.pharmasen.sn/"
							className={`sn_navbar_link`}
						>
							Accueil
						</Nav.Link>
						<Nav.Link
							href="/pharmacies"
							className={`sn_navbar_link ${
								location === "/pharmacies"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Pharmacies
						</Nav.Link>
						<Nav.Link
							href="/medicaments"
							className={`sn_navbar_link ${
								location === "/medicaments"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Medicaments
						</Nav.Link>
						<Nav.Link
							href="/classes-therapeutiques"
							className={`sn_navbar_link ${
								location === "/classes-therapeutiques"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Classes thérapeutiques
						</Nav.Link>
						<Nav.Link
							href="/conseils"
							className={`sn_navbar_link ${
								location === "/conseils"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Conseils
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/coin-sante/"
							className={`sn_navbar_link`}
						>
							Coin Santé
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/actualites/"
							className={`sn_navbar_link`}
						>
							Actualités
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/contact/"
							className={`sn_navbar_link`}
						>
							Contact
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/forum"
							className={`sn_navbar_link`}
						>
							Forum
						</Nav.Link>
					</Nav>
				</Container>
			</Navbar>
			<p>
				<span>
					Copyright{" "}
					<span className="footer_green">
						<strong>©</strong>
					</span>{" "}
					2022{" "}
					<span className="footer_green">
						<strong>PHARMASEN</strong>
					</span>{" "}
					| designed by{" "}
					<span className="footer_green">
						<strong>MEDICOM GROUP</strong>
					</span>
				</span>
			</p>
		</div>
	);
}

export default Footer;
