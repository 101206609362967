import axios from "axios";
import React from "react";
import { Container } from "react-bootstrap";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import AdminPrivateRoute from "./AdminPrivateRoute";
import Login from "./components/frontend/auth/Login";
import Register from "./components/frontend/auth/Register";
import ClassesTherapeutiquesView from "./components/frontend/ClassesTherapeutiques/ClassesTherapeutiquesView";
import ConseilsView from "./components/frontend/Conseils/ConseilsView";
//import MasterLayout from "./layouts/admin/MasterLayout";
import Home from "./components/frontend/Home";
import MedicamentsView from "./components/frontend/Medicaments/MedicamentsView";
import PharmaciesView from "./components/frontend/Pharmacies/PharmaciesView";
import Footer from "./layouts/frontend/Footer";
import Header from "./layouts/frontend/Header";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://apipharma.pharmasen.sn/";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem("auth_token");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});
function App() {
	return (
		<div className="App">
			<Router>
				<Header />
				<Container className="mt-5 p-0" id="content">
					<Switch>
						<Route exact path="/" component={Home} />
						<Route
							exact
							path="/pharmacies"
							component={PharmaciesView}
						/>
						<Route
							exact
							path="/medicaments"
							component={MedicamentsView}
						/>
						<Route
							exact
							path="/classes-therapeutiques"
							component={ClassesTherapeutiquesView}
						/>
						<Route
							exact
							path="/conseils"
							component={ConseilsView}
						/>
						{/* <Route path="/login" component={Login} />
          <Route path="/register" component={Register} /> */}
						<Route path="/login">
							{localStorage.getItem("auth_token") ? (
								<Redirect to="/" />
							) : (
								<Login />
							)}
						</Route>
						<Route path="/register">
							{localStorage.getItem("auth_token") ? (
								<Redirect to="/" />
							) : (
								<Register />
							)}
						</Route>

						{/* <Route
            path="/admin"
            name="Admin"
            render={(props) => <MasterLayout {...props} />}
            component={MasterLayout}
          /> */}
						<AdminPrivateRoute path="/admin" name="Admin" />
					</Switch>
				</Container>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
