import {
    Container,
    Row,
    Col,
    Accordion,
}  from 'react-bootstrap';

function ConseilsItem({conseil}) {
    return(
    <Container className='p-2'>
    <Row>
      <Col sm={2}></Col>
      <Col sm={8}>
      <Accordion.Item eventKey={conseil.id}>
                <Accordion.Header> <strong> {conseil.titre} </strong>  : {conseil.description} </Accordion.Header>
                <Accordion.Body>
                  {conseil.resume}
                </Accordion.Body>
    </Accordion.Item>
    </Col>
    </Row>
  </Container>
    );
}
export default ConseilsItem;
