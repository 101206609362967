import axios from "axios";
import { useEffect, useState } from "react";
import { menu_infos } from "../../../data/menu_infos";
import ConseilsList from "./ConseilsList";

function ConseilsView() {
	const [loading, setLoading] = useState(true);
	const [allData, setAllData] = useState([]);
	const infos = menu_infos.filter((menu) => menu.link === "/conseils")[0];

	useEffect(() => {
		axios
			.get(`/api/view-conseil`)
			.then((res) => {
				const conseils = res.data.conseil;
				setAllData(conseils);
				setLoading(false);
			})
			.catch((err) => {
				console.log("ERROR getting conseils: " + err);
			});
	}, []);
	/*
    if (loading) {
        return <div className="text-danger">Loading...</div>;
    } else {
        console.log("Pharmacies : ", pharmacies);
    }
*/
	if (loading) {
		return (
			<div className="wrapper">
				<div className="box-wrap">
					<div className="box one"></div>
					<div className="box two"></div>
					<div className="box three"></div>
					<div className="box four"></div>
					<div className="box five"></div>
					<div className="box six"></div>
				</div>
			</div>
		);
	}
	return (
		<div>

			<div className='publicity-side'>
				<h1> ESPACE PUBLICITAIRE </h1>
				<p> Pour vos publicités contactez-nous </p>
				<a href='tel:+221774858415'> (+221) 77 437 36 63 </a>
			</div>

			<h1 className="text-center text-dark pt-3">{infos.title}</h1>
			<p className="text-center text-dark">{infos.description}</p>
			<ConseilsList conseils={allData} />
		</div>
	);
}
export default ConseilsView;
