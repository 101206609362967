import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import { menu_infos } from "../../../data/menu_infos";
import MedicamentsList from "./MedicamentsList";
import "./medoc-card.css";

function MedicamentsView() {
	const [loading, setLoading] = useState(true);
	const [allData, setAllData] = useState([]);
	//const [filteredData, setFilteredData] = useState(allData);
	const [filteredData, setFilteredData] = useState([]);
	const [displayFilters, setDisplayFilters] = useState(true);
	const [formValues, setFormValues] = useState(null);
	const infos = menu_infos.filter((menu) => menu.link === "/medicaments")[0];

	useEffect(() => {
		axios
			.get(`/api/view-medicament`)
			.then((res) => {
				const medicaments = res.data.medicament;
				setAllData(medicaments);
				//setFilteredData(medicaments);
				setLoading(false);
			})
			.catch((err) => {
				console.log("ERROR getting medicaments: " + err);
			});
	}, []);

	const handleSearch = (event) => {
		event.preventDefault();
		const nom = event.target[0].value.toLowerCase();
		const DCI = event.target[1].value.toLowerCase();
		const classe_therapeutique = event.target[2].value.toLowerCase();
		const dosage = event.target[3].value.toLowerCase();

		setFilteredData(
			allData.filter(
				(pharmacie) =>
					pharmacie.medicament_nom.toLowerCase().includes(nom) &&
					pharmacie.DCI.toLowerCase().includes(DCI) &&
					pharmacie.classe_therapeutique
						.toLowerCase()
						.includes(classe_therapeutique) &&
					pharmacie.dosage.includes(dosage)
			)
		);
		setFormValues({
			nom: nom,
			DCI: DCI,
			classe_therapeutique: classe_therapeutique,
			dosage: dosage,
		});
	};

	if (loading) {
		return (
			<div className="wrapper">
				<div className="box-wrap">
					<div className="box one"></div>
					<div className="box two"></div>
					<div className="box three"></div>
					<div className="box four"></div>
					<div className="box five"></div>
					<div className="box six"></div>
				</div>
			</div>
		);
	}

	return (
		<div>

			<div className='publicity-side'>
				<h1> ESPACE PUBLICITAIRE </h1>
				<p> Pour vos publicités contactez-nous </p>
				<a href='tel:+221774858415'> (+221) 77 437 36 63 </a>
			</div>

			<h1 className="text-center text-dark pt-3">{infos.title}</h1>
			<p className="text-center text-dark">{infos.description}</p>
			<div id="filter-section" className="pb-3">
				<Row xs="auto" className="m-0 justify-content-center">
					{displayFilters ? (
						<Button
							className="mb-3"
							variant="secondary"
							onClick={() => setDisplayFilters(!displayFilters)}
						>
							<RiFilterOffFill />
							Fermer
						</Button>
					) : (
						<Button
							size="lg"
							className="mb-3"
							variant="outline-secondary"
							onClick={() => setDisplayFilters(!displayFilters)}
						>
							<RiFilterFill />
							Afficher les filtres
						</Button>
					)}
				</Row>
				{displayFilters ? (
					<Form onSubmit={(event) => handleSearch(event)}>
						<FloatingLabel
							controlId="inputNom"
							label="Nom médicament"
							className="mb-3"
						>
							<Form.Control
								type="text"
								placeholder="Mettez ici le nom du médicament"
								name="nom"
								defaultValue={formValues?.nom}
							/>
						</FloatingLabel>
						<Row>
							<Col md>
								<FloatingLabel
									controlId="inputClasseTherapeuthique"
									label="DCI"
									className="mb-3"
								>
									<Form.Control
										type="text"
										placeholder="Mettez ici la DCI"
										name="DCI"
										defaultValue={formValues?.DCI}
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel
									controlId="inputClasseTherapeuthique"
									label="Classe thérapeuthique"
									className="mb-3"
								>
									<Form.Control
										type="text"
										placeholder="Mettez ici la classe thérapeuthique"
										name="classe_therapeutique"
										defaultValue={
											formValues?.classe_therapeutique
										}
									/>
								</FloatingLabel>
							</Col>
							<Col md>
								<FloatingLabel
									controlId="inputDosage"
									label="Dosage (mg)"
									className="mb-3"
								>
									<Form.Control
										type="number"
										placeholder="Mettez ici le dosage du médicament"
										name="dosage"
										defaultValue={formValues?.dosage}
									/>
								</FloatingLabel>
							</Col>
						</Row>

						<Row xs="auto" className="m-0 justify-content-center">
							<Col>
								<Button type="submit" variant="outline-success">
									Rechercher
								</Button>
							</Col>
							<Col>
								<Button
									variant="outline-secondary"
									onClick={() => {
										//setFilteredData(allData);
										setFilteredData([]);
										//setDisplayFilters(false);
										setFormValues(null);
									}}
								>
									Réinitialiser
								</Button>
							</Col>
						</Row>
					</Form>
				) : null}
			</div>
			{filteredData.length === 0 ? (
				<p className="text-center text-dark mb-5">
					Veuillez faire une recherche pour voir des médicaments.
				</p>
			) : (
				<MedicamentsList medicaments={filteredData} />
			)}
			<div className="pharmasen-forms">
				<div className="pharmasen-forms-one">
					<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSee6qE3BWIIEz06lyUVxDvfQ3cno0Ox3dDeQnBkCLJ9lgC9Mg/viewform?embedded=true" title="Pharmasen Suggestion Médicaments" frameBorder="0" marginHeight="0" marginWidth="0">SALAM</iframe>
				</div>
			</div>
		</div>
	);
}
export default MedicamentsView;
