import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import brand_logo from "../../assets/images/pharmasene-logo-300x138.png";

function NavbarFront() {
	const location = useLocation().pathname;

	return (
		<Navbar
			collapseOnSelect
			expand="lg"
			variant="light"
			className="border-bottom p-0"
			id="navbar_front"
		>
			<Container fluid>
				<a
					href="https://www.pharmasen.sn/"
					id="sn_navbar_brand_container"
				>
					<Image
						src={brand_logo}
						width="150"
						height="69"
						className="ms-3 me-5"
					/>
				</a>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto ms-4">
						<Nav.Link
							href="https://www.pharmasen.sn/"
							className={`sn_navbar_link`}
						>
							Accueil
						</Nav.Link>
						<Nav.Link
							href="/pharmacies"
							className={`sn_navbar_link ${
								location === "/pharmacies"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Pharmacies
						</Nav.Link>
						<Nav.Link
							href="/medicaments"
							className={`sn_navbar_link ${
								location === "/medicaments"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Médicaments
						</Nav.Link>
						<Nav.Link
							href="/classes-therapeutiques"
							className={`sn_navbar_link ${
								location === "/classes-therapeutiques"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Recherche Disponibilité
						</Nav.Link>
						<Nav.Link
							href="/conseils"
							className={`sn_navbar_link ${
								location === "/conseils"
									? "sn_navbar_link_active"
									: ""
							}`}
						>
							Conseils
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/coin-sante/"
							className={`sn_navbar_link`}
						>
							Coin Santé
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/actualites/"
							className={`sn_navbar_link`}
						>
							Actualités
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/contact/"
							className={`sn_navbar_link`}
						>
							Contact
						</Nav.Link>
						<Nav.Link
							href="https://www.pharmasen.sn/forum"
							className={`sn_navbar_link`}
						>
							Forum
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default NavbarFront;
